import React, { useState } from "react";
import {
  motion,
  // AnimatePresence,
  useViewportScroll,
  useTransform,
} from "framer-motion";
import Sticky from "react-sticky-el";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import KnowMore from "root/components/KnowMore";
import HomePhoneBase from "root/components/SvgImages/HomePhoneBase";
import HomePhoneApp from "root/components/SvgImages/HomePhoneApp";
import HomePhoneDrivit from "root/components/SvgImages/HomePhoneDrivit";
import phoneBaseAlone from "root/assets/home-phone-base-alone.svg";
import phoneDrivit from "root/assets/home-phone-layer-drivit.svg";
import phoneApp from "root/assets/home-phone-layer-app.svg";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import supportSmil from "root/utils/supportSmil";

// import utrustLogo from "root/assets/utrust-logo-grey.svg";

import styles from "./index.module.css";

const tabletW = 1100;

const HomeFeatures = () => {
  const { scrollYProgress } = useViewportScroll();
  const [dropdown, setDropdown] = useState([false, false, false]);
  const dimensions = useWindowDimensions();

  const handleOpenDropdown = which => event => {
    event.preventDefault();

    setDropdown(dropdown.map((bool, index) => index === which && !bool));
  };

  return (
    <div className="stickyBlock">
      <Section color="white">
        <div className={styles.root}>
          <div className={styles.title}>
            <Typography variant="h1" color="green" weight="bold">
              A plug &amp; play driving analytics solution
            </Typography>
            <div className={styles.subtitle}>
              <Typography color="black">
                Drivit is an award-winning driving technology that turns any
                smartphone into a high-quality telematics device
              </Typography>
            </div>
          </div>
        </div>
      </Section>
      <Section>
        <div className={styles.feature}>
          <div className={styles.right}>
            <div className={styles.feature1}>
              <div className={styles.featureText}>
                <Typography variant="h2" color="black" weight="bold">
                  Record and analyze all the trips of your users
                </Typography>
                <div className={styles.featureDescription}>
                  <Typography color="black">
                    Capture mobility data using your preferred mobile app. Just
                    plug Drivit into your app or use our white-label solution.
                    No extra hardware, runs automatically in the background.
                  </Typography>
                </div>
                <div className={styles.knowMore}>
                  <KnowMore
                    dropdown={dropdown[0]}
                    handleDropdown={handleOpenDropdown(0)}
                  />
                </div>
              </div>
              {dimensions.width < tabletW ? (
                <div className={styles.imgDivMobile}>
                  <img
                    className={styles.phoneBaseFirstFeature}
                    alt=""
                    src={phoneBaseAlone}
                  />
                </div>
              ) : null}
              {/*
              <div className={styles.featureText}>
                <AnimatePresence initial={false}>
                  {!dropdown[0] && (
                    <motion.div
                      className={styles.quote}
                      key="quote"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: 0.3,
                          ease: "easeIn",
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        y: 50,
                        transition: {
                          ease: "easeOut",
                          duration: 0.2,
                        },
                      }}
                    >
                      <div className={styles.apostrophe}>
                        <Typography color="grey">&quot;</Typography>
                      </div>
                      <div className={styles.quoteText}>
                        <Typography color="grey">
                          In-context messaging lets us form 1:1 connections with
                          our users. They now quickly find value with our
                          product.
                        </Typography>
                      </div>
                      <div className={styles.author}>
                        <Typography color="grey">
                          <b>Annie Mosbacher, </b>
                          VP of Customer Engagement
                        </Typography>
                        <img
                          className={styles.logo}
                          alt="utrust logo"
                          src={utrustLogo}
                        />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
              </div>
                    */}
            </div>
            <div className={styles.feature2}>
              <div className={styles.feature2Right}>
                <div className={styles.featureText}>
                  <Typography variant="h2" color="black" weight="bold">
                    AI-powered quality and accuracy
                  </Typography>
                  <div className={styles.featureDescription}>
                    <Typography color="black">
                      Supported by machine learning and low-power sensors,
                      Drivit spends up to 10x less battery than alternatives and
                      yet provides higher accuracies in the metrics that matter
                      to your business.
                    </Typography>
                  </div>
                  <div className={styles.knowMore}>
                    <KnowMore
                      dropdown={dropdown[1]}
                      handleDropdown={handleOpenDropdown(1)}
                    />
                  </div>
                </div>
                {dimensions.width < tabletW ? (
                  <div className={styles.imgDivMobile}>
                    <img
                      className={styles.phoneBaseSecondFeature}
                      alt=""
                      src={phoneBaseAlone}
                    />
                    <img
                      className={styles.phoneDrivitSecondFeature}
                      alt=""
                      src={phoneDrivit}
                    />
                  </div>
                ) : null}
                {/*
                <div className={styles.featureText}>
                  <AnimatePresence initial={false}>
                    {!dropdown[1] && (
                      <motion.div
                        className={styles.quote}
                        key="quote"
                        initial={{
                          opacity: 0,
                          y: 50,
                        }}
                        animate={{
                          opacity: 1,
                          y: 0,
                          transition: {
                            delay: 0.3,
                            ease: "easeIn",
                            duration: 0.3,
                          },
                        }}
                        exit={{
                          opacity: 0,
                          y: 50,
                          transition: {
                            ease: "easeOut",
                            duration: 0.2,
                          },
                        }}
                      >
                        <div className={styles.apostrophe}>
                          <Typography color="grey">&quot;</Typography>
                        </div>
                        <div className={styles.quoteText}>
                          <Typography color="grey">
                            In-context messaging lets us form 1:1 connections
                            with our users. They now quickly find value with our
                            product.
                          </Typography>
                        </div>
                        <div className={styles.author}>
                          <Typography color="grey">
                            <b>Annie Mosbacher, </b>
                            VP of Customer Engagement
                          </Typography>
                          <img
                            className={styles.logo}
                            alt="utrust logo"
                            src={utrustLogo}
                          />
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
                      */}
              </div>
            </div>
            <div className={styles.feature3}>
              <div className={styles.featureText}>
                <Typography variant="h2" color="black" weight="bold">
                  Powerful building blocks
                </Typography>
                <div className={styles.featureDescription}>
                  <Typography color="black">
                    Our unique mobility features can be combined at your choice
                    into groundbreaking mobility products, accelerating the time
                    to market to your users with quality approved services by
                    our customers.
                  </Typography>
                </div>
                <div className={styles.knowMore}>
                  <KnowMore
                    dropdown={dropdown[2]}
                    handleDropdown={handleOpenDropdown(2)}
                  />
                </div>
              </div>
              {dimensions.width < tabletW ? (
                <div className={styles.imgDivMobile}>
                  <img
                    className={styles.phoneBaseThirdFeature}
                    alt=""
                    src={phoneBaseAlone}
                  />
                  <img
                    className={styles.phoneDrivitThirdFeature}
                    alt=""
                    src={phoneDrivit}
                  />
                  <img
                    className={styles.phoneAppThirdFeature}
                    alt=""
                    src={phoneApp}
                  />
                </div>
              ) : null}
              {/*
              <div className={styles.featureText}>
                <AnimatePresence initial={false}>
                  {!dropdown[2] && (
                    <motion.div
                      className={styles.quote}
                      key="quote"
                      initial={{
                        opacity: 0,
                        y: 50,
                      }}
                      animate={{
                        opacity: 1,
                        y: 0,
                        transition: {
                          delay: 0.3,
                          ease: "easeIn",
                          duration: 0.3,
                        },
                      }}
                      exit={{
                        opacity: 0,
                        y: 50,
                        transition: {
                          ease: "easeOut",
                          duration: 0.2,
                        },
                      }}
                    >
                      <div className={styles.apostrophe}>
                        <Typography color="grey">&quot;</Typography>
                      </div>
                      <div className={styles.quoteText}>
                        <Typography color="grey">
                          In-context messaging lets us form 1:1 connections with
                          our users. They now quickly find value with our
                          product.
                        </Typography>
                      </div>
                      <div className={styles.author}>
                        <Typography color="grey">
                          <b>Annie Mosbacher, </b>
                          VP of Customer Engagement
                        </Typography>
                        <img
                          className={styles.logo}
                          alt="utrust logo"
                          src={utrustLogo}
                        />
                      </div>
                    </motion.div>
                  )}
                </AnimatePresence>
                   
              </div>
               */}
            </div>
          </div>
          <div className={styles.left}>
            <Sticky boundaryElement=".stickyBlock">
              <div className={styles.imgDivDesktop}>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.2, 0.57],
                      [1, 0.3],
                    ),
                    y: useTransform(scrollYProgress, [0.2, 0.57], [-80, 0]),
                  }}
                >
                  {supportSmil() ? (
                    <div className={styles.phoneBaseThirdFeature}>
                      <HomePhoneBase />
                    </div>
                  ) : (
                    <img
                      className={styles.phoneBaseThirdFeatureEdge}
                      alt=""
                      src={phoneBaseAlone}
                    />
                  )}
                </motion.div>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.38, 0.4, 0.6],
                      [0, 1, 0.6],
                    ),
                    y: useTransform(scrollYProgress, [0.4, 0.57], [-100, 0]),
                  }}
                >
                  {supportSmil() ? (
                    <div className={styles.phoneDrivitThirdFeature}>
                      <HomePhoneDrivit />
                    </div>
                  ) : (
                    <img
                      className={styles.phoneDrivitThirdFeature}
                      alt=""
                      src={phoneDrivit}
                    />
                  )}
                </motion.div>
                <motion.div
                  style={{
                    opacity: useTransform(
                      scrollYProgress,
                      [0.53, 0.56],
                      [0, 1],
                    ),
                    y: useTransform(scrollYProgress, [0.53, 0.56], [2, 0]),
                  }}
                >
                  {supportSmil() ? (
                    <div className={styles.phoneAppThirdFeature}>
                      <HomePhoneApp />
                    </div>
                  ) : (
                    <img
                      className={styles.phoneAppThirdFeature}
                      alt=""
                      src={phoneApp}
                    />
                  )}
                </motion.div>
              </div>
            </Sticky>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default HomeFeatures;
