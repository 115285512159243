import React from "react";
import Typography from "root/components/Typography";
import Section from "root/components/Section";
import HomeHeroMap from "root/components/SvgImages/HomeHeroMap";
import GetStartedEmail from "root/components/GetStartedEmail";
import heroImgSvg from "root/assets/hero-map.svg";
import supportSmil from "root/utils/supportSmil";
import zegoLogo from "root/assets/zego.svg";
import styles from "./index.module.css";

const HomeHero = () => {
  return (
    <Section color="darkGreen">
      <div className={styles.root}>
        <div className={styles.imgDiv}>
          <div className={styles.heroMap}>
            {supportSmil() ? (
              <HomeHeroMap />
            ) : (
              <img className={styles.heroMapSvg} alt="" src={heroImgSvg} />
            )}
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.contentText}>
            <Typography variant="h1" color="white" weight="bold">
              Making mobility safer and more sustainable
            </Typography>
            <div className={styles.text}>
              <Typography variant="h3" color="white">
                Power your app with the new generation of mobility data and
                services
              </Typography>
            </div>
          </div>
          <div className={styles.emailInput}>
            <GetStartedEmail color="darkGreen" />
          </div>
          <br />
          <br />
          <div className={styles.text}>
            <Typography variant="h3" color="white">
              Drivit is now part of <img src={zegoLogo} className={styles.logoSmall} alt="" />. Read
              more about it <a href="https://www.zego.com/blog/zego-acquires-telematics-pioneer-drivit-to-the/">here!</a>
            </Typography>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default HomeHero;
