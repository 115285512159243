import React from "react";
import { Link } from "gatsby";

import Typography from "root/components/Typography";
import HomePhoneBase from "root/components/SvgImages/HomePhoneBase";
import HomePhoneApp from "root/components/SvgImages/HomePhoneApp";
import HomePhoneDrivit from "root/components/SvgImages/HomePhoneDrivit";
import phoneBaseAlone from "root/assets/home-phone-base-alone.svg";
import phoneDrivit from "root/assets/home-phone-layer-drivit.svg";
import phoneApp from "root/assets/home-phone-layer-app.svg";
import Section from "root/components/Section";
import useWindowDimensions from "root/hooks/useWindowDimensions";
import supportSmil from "root/utils/supportSmil";

import greenArrow from "root/assets/arrow-green.svg";

import styles from "./index.module.css";

const tabletW = 1100;

const HomeHowItWorks = () => {
  const dimensions = useWindowDimensions();

  return (
    <Section color="lightGrey">
      <div className={styles.root}>
        <div className={styles.title}>
          <Typography variant="h1" color="green" weight="bold">
            How it works
          </Typography>
        </div>
        <div className={styles.imgDiv}>
          <div>
            {supportSmil() && dimensions.width >= tabletW ? (
              <div className={styles.desktopImg}>
                <div className={styles.phoneBase}>
                  <HomePhoneBase variant="rootHowItWorks" />
                </div>
              </div>
            ) : (
              <img
                className={styles.phoneBaseEdge}
                alt=""
                src={phoneBaseAlone}
              />
            )}
            {supportSmil() && dimensions.width >= tabletW ? (
              <div className={styles.desktopImg}>
                <div className={styles.phoneDrivit}>
                  <HomePhoneDrivit variant="rootHowItWorks" />
                </div>
              </div>
            ) : (
              <img className={styles.phoneDrivit} alt="" src={phoneDrivit} />
            )}
            {supportSmil() && dimensions.width >= tabletW ? (
              <div className={styles.desktopImg}>
                <div className={styles.phoneApp}>
                  <HomePhoneApp variant="rootHowItWorks" />
                </div>
              </div>
            ) : (
              <img className={styles.phoneApp} alt="" src={phoneApp} />
            )}
          </div>
          <div className={styles.phoneAppText}>
            <Typography color="black" weight="bold">
              Your mobility app
            </Typography>
            <div className={styles.lineBlack} />
          </div>
          <div className={styles.phoneDrivitText}>
            <Typography color="green" weight="bold">
              Powered by drivit
            </Typography>
            <div className={styles.lineGreen} />
          </div>
          <div className={styles.phoneBaseText}>
            <Typography color="black" weight="bold">
              Driver&apos;s smartphone
            </Typography>
            <div className={styles.lineBlack} />
          </div>
        </div>
        <div className={styles.diveDeeper}>
          <Link className={styles.link} to="/how-it-works">
            <Typography variant="h3" color="lightGreen" weight="bold">
              Dive deeper into
              <br />
              our technology
              <img src={greenArrow} alt="" className={styles.arrow} />
            </Typography>
          </Link>
        </div>
      </div>
    </Section>
  );
};

export default HomeHowItWorks;
