import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

import downArrow from "root/assets/dropdown.svg";
import arrow from "root/assets/dropdown-arrow.svg";
import downBoldArrow from "root/assets/dropdown-bold.svg";

import { motion, AnimatePresence } from "framer-motion";

import Typography from "../Typography";
import styles from "./index.module.css";

const KNOW_MORE = {
  collapsed: "Know more",
  open: "I'm interested in:",
  dropdown: [{ page: "Insurance", route: "/insurance" }],
};

const KnowMore = ({ dropdown, handleDropdown }) => {
  return (
    <div className={styles.root}>
      <div>
        <button className={styles.title} onClick={handleDropdown}>
          <Typography
            color="lightGreen"
            variant="h3"
            weight={dropdown ? "regular" : "bold"}
          >
            <motion.span
              key={dropdown ? "open" : "collapsed"}
              initial="collapsed"
              animate="open"
              variants={{
                open: { opacity: 1 },
                collapsed: { opacity: 0 },
              }}
              transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
            >
              {dropdown ? KNOW_MORE.open : KNOW_MORE.collapsed}
            </motion.span>
          </Typography>
          <motion.img
            className={styles.icon}
            key={dropdown ? "down" : "downBold"}
            initial="collapsed"
            animate="open"
            variants={{
              open: { rotate: 0, opacity: 1 },
              collapsed: { rotate: 180, opacity: 1 },
            }}
            transition={{
              duration: 0.5,
              ease: [0.04, 0.62, 0.23, 0.98],
            }}
            alt="icon drop"
            src={dropdown ? downArrow : downBoldArrow}
          />
        </button>
      </div>
      <AnimatePresence initial={false}>
        {dropdown && (
          <motion.div
            className={styles.dropdown}
            key="dropdown"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.5, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            {KNOW_MORE.dropdown.map(item => (
              <div>
                <motion.button
                  className={styles.item}
                  key="item"
                  whileHover={{
                    opacity: 0.8,
                    transition: { duration: 0.2 },
                  }}
                  disabled
                >
                  <Typography color="lightGreen" variant="h3" weight="bold">
                    <Link to={item.route}>{item.page}</Link>
                  </Typography>
                  <Link to={item.route}>
                    <img className={styles.icon} alt="icon more" src={arrow} />
                  </Link>
                </motion.button>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

KnowMore.propTypes = {
  handleDropdown: PropTypes.func,
  dropdown: PropTypes.bool,
};

KnowMore.defaultProps = {
  handleDropdown: null,
  dropdown: false,
};

export default KnowMore;
