import React from "react";

import SEO from "root/components/SEO";
import Layout from "root/components/Layout";
import Header from "root/sections/Header";
import HomeHero from "root/sections/HomeHero";
import HomeFeatures from "root/sections/HomeFeatures";
import HomeHowItWorks from "root/sections/HomeHowItWorks";
import HomePerfectFor from "root/sections/HomePerfectFor";
import Footer from "root/sections/Footer";

const METATAGS = {
  title: "Drivit",
  description:
    "Drivit turns any smartphone into a high-quality telematics device. Power your mobility app with features that will show you the best way to a safer, cheaper and more sustainable driving.",
  keywords:
    "Driving app, Mobility app, Mobility services, Driving analytics, Distracted driving, Mileage insurance, Distracted driving tracking, Aggressive driving tracking, Telematics, Connected car, Driving analytics, Driving SDK, Mobility SDK ,Driving white-label, Mobility white-label, Telematics tools, Hardware-free telematics, Machine learning telematics, Artificial intelligence driving analytics, AI driving analytics, Artificial intelligence telematics, Safe driving, Safe routes, How to make driving more sustainable",
};

const IndexPage = () => {
  return (
    <div>
      <SEO
        title={METATAGS.title}
        description={METATAGS.description}
        keywords={METATAGS.keywords}
      />
      <Layout>
        <Header background="darkGreen" />
        <HomeHero />
        <HomeFeatures />
        <HomeHowItWorks />
        <HomePerfectFor />
        <Footer />
      </Layout>
    </div>
  );
};

export default IndexPage;
