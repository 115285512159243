import React, { useState, useEffect } from "react";

import Typography from "root/components/Typography";
import Section from "root/components/Section";
import GetStartedEmail from "root/components/GetStartedEmail";

import { motion, AnimatePresence } from "framer-motion";
import styles from "./index.module.css";

const HomePerfectFor = () => {
  const [index, setIndex] = useState(0);

  const perfectFor = [
    "usage-based insurance",
    "pay-per-mile insurance",
    "accident detection",
    "driving behavior analysis",
    "route optimization",
    "consumption and efficiency",
    "connected car data",
    "mobility services",
  ];

  useEffect(() => {
    let nextTimer = setTimeout(
      () => setIndex((index + 1) % perfectFor.length),
      2700,
    );

    const onFocus = () => {
      clearTimeout(nextTimer);
      nextTimer = setTimeout(
        () => setIndex((index + 1) % perfectFor.length),
        2700,
      );
    };

    const onBlur = () => {
      clearTimeout(nextTimer);
    };

    window.addEventListener("focus", onFocus);
    window.addEventListener("blur", onBlur);

    return () => {
      clearTimeout(nextTimer);
      window.removeEventListener("focus", onFocus);
      window.removeEventListener("blur", onBlur);
    };
  }, [index, perfectFor.length]);

  return (
    <Section color="green">
      <div className={styles.root}>
        <div className={styles.title}>
          <Typography variant="h1" color="white">
            Your perfect partner for
          </Typography>
        </div>
        <div className={styles.animation}>
          <AnimatePresence initial={false} exitBeforeEnter>
            <motion.div
              key={index}
              enter={{ y: 70 }}
              initial={{ y: 70 }}
              // animate={animationControls}
              animate={{ y: 0 }}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 200 },
                opacity: { duration: 0.5 },
              }}
              exit={{ y: -70 }}
            >
              <Typography variant="h1" color="white" weight="bold">
                {perfectFor[index]}
              </Typography>
            </motion.div>
          </AnimatePresence>
        </div>

        <div className={styles.emailInput}>
          <GetStartedEmail color="white" />
        </div>
      </div>
    </Section>
  );
};

export default HomePerfectFor;
