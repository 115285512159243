import React from "react";
import PropTypes from "prop-types";

import styles from "./homePhoneDrivit.module.css";

const HomePhoneDrivit = ({ variant }) => {
  return (
    <svg
      className={styles[variant]}
      viewBox="0 0 482 264"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M186.902 258.716L471.431 107.891C475.706 105.625 475.678 99.4901 471.382 97.2629L314.079 15.7037C308.258 12.6858 301.328 12.7125 295.53 15.7753L10.8925 166.154C6.61586 168.413 6.63325 174.545 10.9227 176.78L168.293 258.782C174.129 261.823 181.087 261.799 186.902 258.716Z"
        fill="url(#paint25_radial)"
      />
      {/* circles */}
      <circle opacity="0.4" cx="16.4261" cy="163.476" r="2" fill="#149082" />
      <circle opacity="0.4" cx="32.4261" cy="155.002" r="2" fill="#149082" />
      <circle opacity="0.4" cx="48.4261" cy="146.529" r="2" fill="#149082" />
      <circle opacity="0.4" cx="64.4261" cy="138.055" r="2" fill="#149082" />
      <circle opacity="0.4" cx="80.4261" cy="129.581" r="2" fill="#149082" />
      <circle opacity="0.4" cx="96.4261" cy="121.108" r="2" fill="#149082" />
      <circle opacity="0.4" cx="112.426" cy="112.634" r="2" fill="#149082" />
      <circle opacity="0.4" cx="128.426" cy="104.16" r="2" fill="#149082" />
      <circle opacity="0.4" cx="144.426" cy="95.6868" r="2" fill="#149082" />
      <circle opacity="0.4" cx="160.426" cy="87.2131" r="2" fill="#149082" />
      <circle opacity="0.4" cx="176.426" cy="78.7395" r="2" fill="#149082" />
      <circle opacity="0.4" cx="192.426" cy="70.2659" r="2" fill="#149082" />
      <circle opacity="0.4" cx="208.426" cy="61.7922" r="2" fill="#149082" />
      <circle opacity="0.4" cx="224.426" cy="53.3186" r="2" fill="#149082" />
      <circle opacity="0.4" cx="240.426" cy="44.845" r="2" fill="#149082" />
      <circle opacity="0.4" cx="256.426" cy="36.3713" r="2" fill="#149082" />
      <circle opacity="0.4" cx="272.426" cy="27.8977" r="2" fill="#149082" />
      <circle opacity="0.4" cx="288.426" cy="19.4241" r="2" fill="#149082" />
      <circle opacity="0.4" cx="18.1038" cy="181.148" r="2" fill="#149082" />
      <circle opacity="0.4" cx="34.1038" cy="172.674" r="2" fill="#149082" />
      <circle opacity="0.4" cx="50.1038" cy="164.201" r="2" fill="#149082" />
      <circle opacity="0.4" cx="66.1038" cy="155.727" r="2" fill="#149082" />
      <circle opacity="0.4" cx="82.1038" cy="147.253" r="2" fill="#149082" />
      <circle opacity="0.4" cx="98.1038" cy="138.78" r="2" fill="#149082" />
      <circle opacity="0.4" cx="114.104" cy="130.306" r="2" fill="#149082" />
      <circle opacity="0.4" cx="130.104" cy="121.833" r="2" fill="#149082" />
      <circle opacity="0.4" cx="146.104" cy="113.359" r="2" fill="#149082" />
      <circle opacity="0.4" cx="162.104" cy="104.885" r="2" fill="#149082" />
      <circle opacity="0.4" cx="178.104" cy="96.4116" r="2" fill="#149082" />
      <circle opacity="0.4" cx="194.104" cy="87.938" r="2" fill="#149082" />
      <circle opacity="0.4" cx="210.104" cy="79.4644" r="2" fill="#149082" />
      <circle opacity="0.4" cx="226.104" cy="70.9907" r="2" fill="#149082" />
      <circle opacity="0.4" cx="242.104" cy="62.5171" r="2" fill="#149082" />
      <circle opacity="0.4" cx="258.104" cy="54.0435" r="2" fill="#149082" />
      <circle opacity="0.4" cx="274.104" cy="45.5698" r="2" fill="#149082" />
      <circle opacity="0.4" cx="290.104" cy="37.0962" r="2" fill="#149082" />
      <circle opacity="0.4" cx="306.104" cy="28.6226" r="2" fill="#149082" />
      <circle opacity="0.4" cx="322.104" cy="20.1489" r="2" fill="#149082" />
      <circle opacity="0.4" cx="35.7816" cy="190.346" r="2" fill="#149082" />
      <circle opacity="0.4" cx="51.7816" cy="181.873" r="2" fill="#149082" />
      <circle opacity="0.4" cx="67.7816" cy="173.399" r="2" fill="#149082" />
      <circle opacity="0.4" cx="83.7816" cy="164.926" r="2" fill="#149082" />
      <circle opacity="0.4" cx="99.7816" cy="156.452" r="2" fill="#149082" />
      <circle opacity="0.4" cx="115.782" cy="147.978" r="2" fill="#149082" />
      <circle opacity="0.4" cx="131.782" cy="139.505" r="2" fill="#149082" />
      <circle opacity="0.4" cx="147.782" cy="131.031" r="2" fill="#149082" />
      <circle opacity="0.4" cx="163.782" cy="122.557" r="2" fill="#149082" />
      <circle opacity="0.4" cx="179.782" cy="114.084" r="2" fill="#149082" />
      <circle opacity="0.4" cx="195.782" cy="105.61" r="2" fill="#149082" />
      <circle opacity="0.4" cx="211.782" cy="97.1365" r="2" fill="#149082" />
      <circle opacity="0.4" cx="227.782" cy="88.6628" r="2" fill="#149082" />
      <circle opacity="0.4" cx="243.782" cy="80.1892" r="2" fill="#149082" />
      <circle opacity="0.4" cx="259.782" cy="71.7156" r="2" fill="#149082" />
      <circle opacity="0.4" cx="275.782" cy="63.2419" r="2" fill="#149082" />
      <circle opacity="0.4" cx="291.782" cy="54.7683" r="2" fill="#149082" />
      <circle opacity="0.4" cx="307.782" cy="46.2947" r="2" fill="#149082" />
      <circle opacity="0.4" cx="323.782" cy="37.821" r="2" fill="#149082" />
      <circle opacity="0.4" cx="339.782" cy="29.3474" r="2" fill="#149082" />
      <circle opacity="0.4" cx="53.4593" cy="199.545" r="2" fill="#149082" />
      <circle opacity="0.4" cx="69.4593" cy="191.071" r="2" fill="#149082" />
      <circle opacity="0.4" cx="85.4593" cy="182.598" r="2" fill="#149082" />
      <circle opacity="0.4" cx="101.459" cy="174.124" r="2" fill="#149082" />
      <circle opacity="0.4" cx="117.459" cy="165.65" r="2" fill="#149082" />
      <circle opacity="0.4" cx="133.459" cy="157.177" r="2" fill="#149082" />
      <circle opacity="0.4" cx="149.459" cy="148.703" r="2" fill="#149082" />
      <circle opacity="0.4" cx="165.459" cy="140.229" r="2" fill="#149082" />
      <circle opacity="0.4" cx="261.459" cy="89.3877" r="2" fill="#149082" />
      <circle opacity="0.4" cx="277.459" cy="80.9141" r="2" fill="#149082" />
      <circle opacity="0.4" cx="293.459" cy="72.4404" r="2" fill="#149082" />
      <circle opacity="0.4" cx="309.459" cy="63.9668" r="2" fill="#149082" />
      <circle opacity="0.4" cx="325.459" cy="55.4932" r="2" fill="#149082" />
      <circle opacity="0.4" cx="341.459" cy="47.0195" r="2" fill="#149082" />
      <circle opacity="0.4" cx="357.459" cy="38.5459" r="2" fill="#149082" />
      <circle opacity="0.4" cx="71.137" cy="208.743" r="2" fill="#149082" />
      <circle opacity="0.4" cx="87.137" cy="200.27" r="2" fill="#149082" />
      <circle opacity="0.4" cx="103.137" cy="191.796" r="2" fill="#149082" />
      <circle opacity="0.4" cx="119.137" cy="183.323" r="2" fill="#149082" />
      <circle opacity="0.4" cx="135.137" cy="174.849" r="2" fill="#149082" />
      <circle opacity="0.4" cx="151.137" cy="166.375" r="2" fill="#149082" />
      <circle opacity="0.4" cx="167.137" cy="157.902" r="2" fill="#149082" />
      <circle opacity="0.4" cx="183.137" cy="149.428" r="2" fill="#149082" />
      <circle opacity="0.4" cx="279.137" cy="98.5862" r="2" fill="#149082" />
      <circle opacity="0.4" cx="295.137" cy="90.1125" r="2" fill="#149082" />
      <circle opacity="0.4" cx="311.137" cy="81.6389" r="2" fill="#149082" />
      <circle opacity="0.4" cx="327.137" cy="73.1653" r="2" fill="#149082" />
      <circle opacity="0.4" cx="343.137" cy="64.6917" r="2" fill="#149082" />
      <circle opacity="0.4" cx="359.137" cy="56.218" r="2" fill="#149082" />
      <circle opacity="0.4" cx="375.137" cy="47.7444" r="2" fill="#149082" />
      <circle opacity="0.4" cx="88.8148" cy="217.942" r="2" fill="#149082" />
      <circle opacity="0.4" cx="104.815" cy="209.468" r="2" fill="#149082" />
      <circle opacity="0.4" cx="120.815" cy="200.995" r="2" fill="#149082" />
      <circle opacity="0.4" cx="136.815" cy="192.521" r="2" fill="#149082" />
      <circle opacity="0.4" cx="152.815" cy="184.047" r="2" fill="#149082" />
      <circle opacity="0.4" cx="168.815" cy="175.574" r="2" fill="#149082" />
      <circle opacity="0.4" cx="184.815" cy="167.1" r="2" fill="#149082" />
      <circle opacity="0.4" cx="200.815" cy="158.626" r="2" fill="#149082" />
      <circle opacity="0.4" cx="296.815" cy="107.785" r="2" fill="#149082" />
      <circle opacity="0.4" cx="312.815" cy="99.311" r="2" fill="#149082" />
      <circle opacity="0.4" cx="328.815" cy="90.8374" r="2" fill="#149082" />
      <circle opacity="0.4" cx="344.815" cy="82.3638" r="2" fill="#149082" />
      <circle opacity="0.4" cx="360.815" cy="73.8901" r="2" fill="#149082" />
      <circle opacity="0.4" cx="376.815" cy="65.4165" r="2" fill="#149082" />
      <circle opacity="0.4" cx="392.815" cy="56.9429" r="2" fill="#149082" />
      <circle opacity="0.4" cx="106.492" cy="227.14" r="2" fill="#149082" />
      <circle opacity="0.4" cx="122.492" cy="218.667" r="2" fill="#149082" />
      <circle opacity="0.4" cx="138.492" cy="210.193" r="2" fill="#149082" />
      <circle opacity="0.4" cx="154.492" cy="201.719" r="2" fill="#149082" />
      <circle opacity="0.4" cx="170.492" cy="193.246" r="2" fill="#149082" />
      <circle opacity="0.4" cx="186.492" cy="184.772" r="2" fill="#149082" />
      <circle opacity="0.4" cx="202.492" cy="176.299" r="2" fill="#149082" />
      <circle opacity="0.4" cx="218.492" cy="167.825" r="2" fill="#149082" />
      <circle opacity="0.4" cx="314.492" cy="116.983" r="2" fill="#149082" />
      <circle opacity="0.4" cx="330.492" cy="108.51" r="2" fill="#149082" />
      <circle opacity="0.4" cx="346.492" cy="100.036" r="2" fill="#149082" />
      <circle opacity="0.4" cx="362.492" cy="91.5623" r="2" fill="#149082" />
      <circle opacity="0.4" cx="378.492" cy="83.0886" r="2" fill="#149082" />
      <circle opacity="0.4" cx="394.492" cy="74.615" r="2" fill="#149082" />
      <circle opacity="0.4" cx="410.492" cy="66.1414" r="2" fill="#149082" />
      <circle opacity="0.4" cx="124.17" cy="236.339" r="2" fill="#149082" />
      <circle opacity="0.4" cx="140.17" cy="227.865" r="2" fill="#149082" />
      <circle opacity="0.4" cx="156.17" cy="219.392" r="2" fill="#149082" />
      <circle opacity="0.4" cx="172.17" cy="210.918" r="2" fill="#149082" />
      <circle opacity="0.4" cx="188.17" cy="202.444" r="2" fill="#149082" />
      <circle opacity="0.4" cx="204.17" cy="193.971" r="2" fill="#149082" />
      <circle opacity="0.4" cx="220.17" cy="185.497" r="2" fill="#149082" />
      <circle opacity="0.4" cx="236.17" cy="177.023" r="2" fill="#149082" />
      <circle opacity="0.4" cx="332.17" cy="126.182" r="2" fill="#149082" />
      <circle opacity="0.4" cx="348.17" cy="117.708" r="2" fill="#149082" />
      <circle opacity="0.4" cx="364.17" cy="109.234" r="2" fill="#149082" />
      <circle opacity="0.4" cx="380.17" cy="100.761" r="2" fill="#149082" />
      <circle opacity="0.4" cx="396.17" cy="92.2871" r="2" fill="#149082" />
      <circle opacity="0.4" cx="412.17" cy="83.8135" r="2" fill="#149082" />
      <circle opacity="0.4" cx="428.17" cy="75.3398" r="2" fill="#149082" />
      <circle opacity="0.4" cx="141.848" cy="245.537" r="2" fill="#149082" />
      <circle opacity="0.4" cx="157.848" cy="237.064" r="2" fill="#149082" />
      <circle opacity="0.4" cx="173.848" cy="228.59" r="2" fill="#149082" />
      <circle opacity="0.4" cx="189.848" cy="220.116" r="2" fill="#149082" />
      <circle opacity="0.4" cx="205.848" cy="211.643" r="2" fill="#149082" />
      <circle opacity="0.4" cx="221.848" cy="203.169" r="2" fill="#149082" />
      <circle opacity="0.4" cx="237.848" cy="194.696" r="2" fill="#149082" />
      <circle opacity="0.4" cx="253.848" cy="186.222" r="2" fill="#149082" />
      <circle opacity="0.4" cx="269.848" cy="177.748" r="2" fill="#149082" />
      <circle opacity="0.4" cx="285.848" cy="169.275" r="2" fill="#149082" />
      <circle opacity="0.4" cx="301.848" cy="160.801" r="2" fill="#149082" />
      <circle opacity="0.4" cx="317.848" cy="152.327" r="2" fill="#149082" />
      <circle opacity="0.4" cx="333.848" cy="143.854" r="2" fill="#149082" />
      <circle opacity="0.4" cx="349.848" cy="135.38" r="2" fill="#149082" />
      <circle opacity="0.4" cx="365.848" cy="126.906" r="2" fill="#149082" />
      <circle opacity="0.4" cx="381.848" cy="118.433" r="2" fill="#149082" />
      <circle opacity="0.4" cx="397.848" cy="109.959" r="2" fill="#149082" />
      <circle opacity="0.4" cx="413.848" cy="101.486" r="2" fill="#149082" />
      <circle opacity="0.4" cx="429.848" cy="93.012" r="2" fill="#149082" />
      <circle opacity="0.4" cx="445.848" cy="84.5383" r="2" fill="#149082" />
      <circle opacity="0.4" cx="159.526" cy="254.736" r="2" fill="#149082" />
      <circle opacity="0.4" cx="175.526" cy="246.262" r="2" fill="#149082" />
      <circle opacity="0.4" cx="191.526" cy="237.789" r="2" fill="#149082" />
      <circle opacity="0.4" cx="207.526" cy="229.315" r="2" fill="#149082" />
      <circle opacity="0.4" cx="223.526" cy="220.841" r="2" fill="#149082" />
      <circle opacity="0.4" cx="239.526" cy="212.368" r="2" fill="#149082" />
      <circle opacity="0.4" cx="255.526" cy="203.894" r="2" fill="#149082" />
      <circle opacity="0.4" cx="271.526" cy="195.42" r="2" fill="#149082" />
      <circle opacity="0.4" cx="287.526" cy="186.947" r="2" fill="#149082" />
      <circle opacity="0.4" cx="303.526" cy="178.473" r="2" fill="#149082" />
      <circle opacity="0.4" cx="319.526" cy="170" r="2" fill="#149082" />
      <circle opacity="0.4" cx="335.526" cy="161.526" r="2" fill="#149082" />
      <circle opacity="0.4" cx="351.526" cy="153.052" r="2" fill="#149082" />
      <circle opacity="0.4" cx="367.526" cy="144.579" r="2" fill="#149082" />
      <circle opacity="0.4" cx="383.526" cy="136.105" r="2" fill="#149082" />
      <circle opacity="0.4" cx="399.526" cy="127.631" r="2" fill="#149082" />
      <circle opacity="0.4" cx="415.526" cy="119.158" r="2" fill="#149082" />
      <circle opacity="0.4" cx="431.526" cy="110.684" r="2" fill="#149082" />
      <circle opacity="0.4" cx="447.526" cy="102.21" r="2" fill="#149082" />
      <circle opacity="0.4" cx="463.526" cy="93.7368" r="2" fill="#149082" />
      <circle opacity="0.4" cx="193.203" cy="255.461" r="2" fill="#149082" />
      <circle opacity="0.4" cx="209.203" cy="246.987" r="2" fill="#149082" />
      <circle opacity="0.4" cx="225.203" cy="238.513" r="2" fill="#149082" />
      <circle opacity="0.4" cx="241.203" cy="230.04" r="2" fill="#149082" />
      <circle opacity="0.4" cx="257.203" cy="221.566" r="2" fill="#149082" />
      <circle opacity="0.4" cx="273.203" cy="213.093" r="2" fill="#149082" />
      <circle opacity="0.4" cx="289.203" cy="204.619" r="2" fill="#149082" />
      <circle opacity="0.4" cx="305.203" cy="196.145" r="2" fill="#149082" />
      <circle opacity="0.4" cx="321.203" cy="187.672" r="2" fill="#149082" />
      <circle opacity="0.4" cx="337.203" cy="179.198" r="2" fill="#149082" />
      <circle opacity="0.4" cx="353.203" cy="170.724" r="2" fill="#149082" />
      <circle opacity="0.4" cx="369.203" cy="162.251" r="2" fill="#149082" />
      <circle opacity="0.4" cx="385.203" cy="153.777" r="2" fill="#149082" />
      <circle opacity="0.4" cx="401.203" cy="145.303" r="2" fill="#149082" />
      <circle opacity="0.4" cx="417.203" cy="136.83" r="2" fill="#149082" />
      <circle opacity="0.4" cx="433.203" cy="128.356" r="2" fill="#149082" />
      <circle opacity="0.4" cx="449.203" cy="119.883" r="2" fill="#149082" />
      <circle opacity="0.4" cx="465.203" cy="111.409" r="2" fill="#149082" />

      {/* quadrados */}
      <path
        d="M277.676 81.9207L259.793 72.5537L275.842 63.9727L293.79 73.2088L277.676 81.9207Z"
        fill="url(#paint11_linear)"
        fillOpacity="0.8"
        className={styles.square1}
      />
      <path
        d="M277.676 81.9207L259.793 72.5537L275.842 63.9727L293.79 73.2088L277.676 81.9207Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square1Border}
      />

      <path
        d="M327.35 74.0705L309.468 64.7035L325.516 56.1225L343.464 65.3586L327.35 74.0705Z"
        fill="url(#paint12_linear)"
        fillOpacity="0.8"
        className={styles.square2}
      />
      <path
        d="M327.35 74.0705L309.468 64.7035L325.516 56.1225L343.464 65.3586L327.35 74.0705Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square2Border}
      />

      <path
        d="M380.061 101.543L362.179 92.1762L378.227 83.5952L396.175 92.8312L380.061 101.543Z"
        fill="url(#paint13_linear)"
        fillOpacity="0.8"
        className={styles.square3}
      />
      <path
        d="M380.061 101.543L362.179 92.1762L378.227 83.5952L396.175 92.8312L380.061 101.543Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square3Border}
      />

      <path
        d="M349.739 136.061L331.856 126.694L347.905 118.113L365.853 127.349L349.739 136.061Z"
        fill="url(#paint14_linear)"
        fillOpacity="0.8"
        className={styles.square4}
      />
      <path
        d="M349.739 136.061L331.856 126.694L347.905 118.113L365.853 127.349L349.739 136.061Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square4Border}
      />
      <path
        d="M238.061 195.152L220.178 185.785L236.227 177.204L254.175 186.44L238.061 195.152Z"
        fill="url(#paint15_linear)"
        fillOpacity="0.8"
        className={styles.square5}
      />
      <path
        d="M238.061 195.152L220.178 185.785L236.227 177.204L254.175 186.44L238.061 195.152Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square5Border}
      />
      <path
        d="M190.061 221.123L172.178 211.756L188.227 203.175L206.175 212.411L190.061 221.123Z"
        fill="url(#paint16_linear)"
        fillOpacity="0.8"
        className={styles.square6}
      />
      <path
        d="M190.061 221.123L172.178 211.756L188.227 203.175L206.175 212.411L190.061 221.123Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square6Border}
      />
      <path
        d="M122.065 219.397L104.182 210.03L120.23 201.449L138.179 210.685L122.065 219.397Z"
        fill="url(#paint17_linear)"
        fillOpacity="0.8"
        className={styles.square7}
      />
      <path
        d="M122.065 219.397L104.182 210.03L120.23 201.449L138.179 210.685L122.065 219.397Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square7Border}
      />
      <path
        d="M85.6728 183.503L67.7899 174.136L83.8385 165.555L101.787 174.791L85.6728 183.503Z"
        fill="url(#paint18_linear)"
        fillOpacity="0.8"
        className={styles.square8}
      />
      <path
        d="M85.6728 183.503L67.7899 174.136L83.8385 165.555L101.787 174.791L85.6728 183.503Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square8Border}
      />
      <path
        d="M151.35 167.606L133.468 158.239L149.516 149.658L167.464 158.894L151.35 167.606Z"
        fill="url(#paint19_linear)"
        fillOpacity="0.8"
        className={styles.square9}
      />
      <path
        d="M151.35 167.606L133.468 158.239L149.516 149.658L167.464 158.894L151.35 167.606Z"
        stroke="#149082"
        pathLength="100"
        className={styles.square9Border}
      />
      {/* paths interiores */}

      <path
        d="M218.5 168.5L235 160L273 140L256 130L279 118L297 108.5L331.5 127L380 101.5L309.5 64.5L277.5 82L295 91L268.5 105L232 124L249 133.5L219 150L185 168L149.5 149.5L86 183.5L138.5 211L154.5 202.5L172.5 211.5L236 177.5L218.5 168.5Z"
        stroke="#149082"
        pathLength="822"
        className={styles.pathRoute1}
      />

      {/* under gear */}
      <path
        d="M240.124 164.157L189.012 137.523C184.627 135.238 184.733 128.927 189.193 126.791L240.728 102.111C246.19 99.4949 252.543 99.4949 258.005 102.11L309.54 126.789C314 128.925 314.106 135.236 309.721 137.521L258.609 164.157C252.817 167.176 245.916 167.176 240.124 164.157Z"
        fill="url(#paint11_radial)"
      />
      <path
        d="M240.124 150.157L189.012 123.523C184.627 121.238 184.733 114.927 189.193 112.791L240.728 88.1106C246.19 85.4949 252.543 85.4949 258.005 88.1104L309.54 112.789C314 114.925 314.106 121.236 309.721 123.521L258.609 150.157C252.817 153.176 245.916 153.176 240.124 150.157Z"
        fill="url(#paint12_radial)"
      />
      <g filter="url(#filter1_f)">
        <path d="M239.632 153.455L233.366 149.974" stroke="white" />
      </g>
      <g filter="url(#filter2_f)">
        <path d="M239.632 156.935L233.366 153.455" stroke="white" />
      </g>
      <g filter="url(#filter3_f)">
        <path d="M239.632 160.416L233.366 156.935" stroke="white" />
      </g>
      <path d="M239.632 153.455L233.366 149.974" stroke="white" />
      <path d="M239.632 156.935L233.366 153.455" stroke="white" />
      <path d="M239.632 160.416L233.366 156.935" stroke="white" />
      <g filter="url(#filter4_f)">
        <path d="M301.367 131.455L307.632 127.974" stroke="white" />
      </g>
      <g filter="url(#filter5_f)">
        <path d="M301.367 134.935L307.632 131.455" stroke="white" />
      </g>
      <g filter="url(#filter6_f)">
        <path d="M301.367 138.416L307.632 134.935" stroke="white" />
      </g>
      <path d="M301.367 131.455L307.632 127.974" stroke="white" />
      <path d="M301.367 134.935L307.632 131.455" stroke="white" />
      <path d="M301.367 138.416L307.632 134.935" stroke="white" />

      {/* gear */}
      <g opacity="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M266.861 105.124L269.435 104.353L273.165 106.617L272.956 105.533L276.906 102.94V108.889L276.902 108.889L273.777 112.646C274.894 113.845 275.765 115.122 276.368 116.449L282.989 117.796L282.974 124.184L276.348 125.503C276.061 126.126 275.715 126.738 275.312 127.336L276.856 127.12V133.076L276.847 133.071L269.363 137.568L263.129 135.67C261.145 136.342 259.032 136.865 256.837 137.227L254.619 141.221L244.041 141.198L241.842 137.194C239.649 136.823 237.538 136.291 235.557 135.611L229.315 137.48L221.845 132.948L221.853 132.939L221.843 132.944V127.001L223.078 126.702C222.817 126.269 222.584 125.83 222.381 125.385L215.761 124.037L215.775 117.65L222.401 116.331C223.011 115.007 223.887 113.734 225.009 112.54L221.898 108.766L221.901 108.764V102.817L227.676 104.296L227.423 105.446L229.387 104.266L233.583 105.543L232.838 104.157L248.963 99.0129L254.408 100.635L254.708 100.635L254.766 100.741L267.293 104.472L266.861 105.124ZM232.538 127.148L234.195 128.898C227.169 124.043 227.491 116.671 235.151 112.068C243.129 107.274 256.049 107.302 264.006 112.131C271.046 116.403 271.845 123.001 266.411 127.786V125.932L250.343 129.146L232.538 127.148Z"
          fill="#4CA05D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M276.852 127.12L269.363 131.62L263.129 129.722C261.145 130.394 259.032 130.917 256.837 131.279L254.619 135.273L244.041 135.25L241.842 131.246C239.649 130.875 237.538 130.343 235.557 129.662L229.315 131.532L221.845 127L224.974 123.24C223.856 122.041 222.985 120.763 222.381 119.437L215.761 118.089L215.775 111.702L222.401 110.383C223.011 109.059 223.887 107.786 225.009 106.591L221.898 102.818L229.387 98.3177L235.622 100.215C237.605 99.5429 239.718 99.0203 241.913 98.6588L244.13 94.664L254.708 94.6871L256.908 98.6915C259.101 99.0626 261.212 99.5944 263.192 100.275L269.435 98.4051L276.904 102.938L273.777 106.698C274.894 107.897 275.765 109.174 276.368 110.5L282.989 111.848L282.974 118.236L276.348 119.555C275.739 120.878 274.863 122.152 273.74 123.346L276.852 127.12ZM235.113 123.544C243.07 128.373 255.99 128.401 263.968 123.607C271.947 118.813 271.964 111.012 264.006 106.183C256.049 101.354 243.129 101.326 235.151 106.12C227.172 110.914 227.155 118.715 235.113 123.544Z"
          fill="#5BBA6F"
        />
        <animate
          attributeName="opacity"
          dur="2s"
          begin="0s"
          repeatCount="indefinite"
          values="1;1;0;0"
          keyTimes="0;0.25;0.28;1"
        />
      </g>
      <g opacity="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M263.824 102.338L262.204 103.086L243.526 98.9153L238.121 101.545L237.813 101.581L237.785 101.709L231.76 104.64L233.673 106.89C232.824 107.236 232.007 107.611 231.225 108.013L227.057 107.302L227.192 106.6L218.596 106.205V112.13L218.575 112.149L222.824 115.501C222.097 116.803 221.637 118.154 221.454 119.524L220.713 119.775V119.505L215.376 115.633V121.579L215.375 121.58L217.379 127.854L224.295 128.414C224.847 129.084 225.472 129.732 226.165 130.351V135.902L226.164 135.903L226.165 135.904L226.166 135.904L234.926 139.494L240.467 136.937C242.624 137.379 244.863 137.66 247.132 137.773L250.551 141.449L260.937 140.253L261.848 136.078C263.886 135.469 265.793 134.711 267.525 133.821L274.247 134.967L280.175 129.684L280.158 129.671L280.176 129.668V123.744L276.797 124.4C277.035 123.711 277.202 123.013 277.296 122.31L283.372 120.255L283.375 120.256L283.375 120.253L283.375 120.253V114.306L278.693 113.36L278.727 113.766L274.455 113.419C273.441 112.187 272.181 111.034 270.703 109.987L272.585 105.929L263.824 102.338ZM245.519 128.686L268.204 126.015C269.898 123.77 270.494 121.195 269.66 118.585C267.524 111.901 256.823 107.515 245.759 108.789C234.694 110.064 227.455 116.515 229.591 123.199C230.361 125.609 232.244 127.72 234.856 129.375L245.519 128.686Z"
          fill="#4CA05D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M260.937 134.305L250.551 135.501L247.132 131.825C244.863 131.711 242.624 131.431 240.467 130.989L234.926 133.546L226.164 129.955L228.046 125.898C226.569 124.851 225.309 123.698 224.295 122.466L217.379 121.906L215.375 115.632L221.454 113.576C221.637 112.206 222.097 110.854 222.824 109.553L218.575 106.201L224.501 100.919L231.225 102.064C232.957 101.175 234.863 100.416 236.901 99.8069L237.813 95.6325L248.199 94.4365L251.618 98.1121C253.888 98.2256 256.126 98.5064 258.283 98.9483L263.824 96.3903L272.585 99.9811L270.703 104.039C272.181 105.086 273.441 106.239 274.455 107.471L281.37 108.031L283.375 114.305L277.296 116.362C277.113 117.732 276.653 119.083 275.926 120.384L280.175 123.736L274.247 129.018L267.525 127.873C265.793 128.762 263.886 129.521 261.848 130.13L260.937 134.305ZM229.591 117.251C231.727 123.935 242.428 128.321 253.492 127.047C264.557 125.773 271.795 119.321 269.66 112.637C267.524 105.953 256.823 101.567 245.758 102.841C234.694 104.115 227.455 110.567 229.591 117.251Z"
          fill="#5BBA6F"
        />
        <animate
          attributeName="opacity"
          dur="2s"
          begin="0.5s"
          repeatCount="indefinite"
          values="1;1;0;0"
          keyTimes="0;0.25;0.28;1"
        />
      </g>
      <g opacity="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M241.428 100.385H260.342V101.561L266.953 103.259L266.484 107.509C268.277 108.37 269.893 109.361 271.296 110.46L278.224 110.182L278.39 110.426V108.695L282.285 110.214V116.155L282.284 116.155L282.288 116.16L277.055 118.932C277.241 119.841 277.308 120.757 277.254 121.669L282.342 119.958V125.906L282.334 125.908L278.342 131.847L271.405 131.522C270.151 132.507 268.723 133.403 267.146 134.193V138.674L267.148 138.693L267.146 138.693V138.703L267.137 138.696L257.429 141.131L252.86 137.899C250.61 138.06 248.334 138.051 246.082 137.873L241.543 141.077L231.796 138.573L231.797 138.561L231.796 138.562V134.094C230.19 133.288 228.733 132.375 227.454 131.373L220.526 131.651L216.462 125.673L216.472 125.667V119.708L221.512 121.617C221.434 120.66 221.489 119.7 221.676 118.748L216.407 115.938L216.413 115.929L216.408 115.929V109.987L222.097 109.509L221.988 110.06L227.345 110.311C228.733 109.22 230.336 108.239 232.117 107.389L231.601 103.14L241.321 100.702L241.428 100.779V100.385ZM257.38 132.413L257.451 132.395C267.806 129.798 272.69 122.529 268.36 116.159C264.03 109.79 252.125 106.732 241.77 109.329C231.415 111.926 226.531 119.195 230.861 125.565C233.027 128.75 237.087 131.107 241.901 132.343V130.17H257.38V132.413Z"
          fill="#4CA05D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M267.148 132.745L257.429 135.183L252.86 131.951C250.61 132.112 248.334 132.103 246.082 131.925L241.543 135.129L231.796 132.625L232.265 128.376C230.473 127.515 228.857 126.524 227.454 125.425L220.526 125.703L216.462 119.725L221.695 116.954C221.412 115.573 221.405 114.178 221.676 112.8L216.407 109.99L220.406 104.038L227.345 104.363C228.733 103.272 230.336 102.291 232.117 101.441L231.601 97.1922L241.321 94.7543L245.89 97.9861C248.14 97.8251 250.416 97.8336 252.667 98.0117L257.206 94.8077L266.953 97.3114L266.484 101.561C268.277 102.422 269.893 103.413 271.296 104.512L278.224 104.234L282.288 110.212L277.055 112.984C277.338 114.364 277.344 115.759 277.074 117.137L282.342 119.947L278.342 125.899L271.405 125.574C270.017 126.665 268.414 127.646 266.632 128.496L267.148 132.745ZM230.861 119.617C235.192 125.986 247.096 129.044 257.451 126.447C267.806 123.85 272.69 116.581 268.36 110.211C264.03 103.842 252.125 100.784 241.77 103.381C231.415 105.978 226.531 113.247 230.861 119.617Z"
          fill="#5BBA6F"
        />
        <animate
          attributeName="opacity"
          dur="2s"
          begin="1s"
          repeatCount="indefinite"
          values="1;1;0;0"
          keyTimes="0;0.25;0.28;1"
        />
      </g>
      <g opacity="1">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M267.587 108.092C267.559 108.077 267.53 108.062 267.502 108.047L271.963 105.403L261.112 102.055L261.021 101.631L258.927 101.381L253.538 99.718L236.234 101.751L236.532 102.989L235.007 102.276L226.23 105.828L228.093 109.89C226.613 110.932 225.351 112.081 224.334 113.309L220.134 113.631L220.053 112.411L215.376 114.155V120.104L215.378 120.104L215.377 120.106L221.453 122.191C221.514 122.672 221.61 123.15 221.738 123.625L218.54 123.6V129.547L218.537 129.55L218.54 129.553V129.553L218.541 129.553L224.44 134.858L231.162 133.742C232.892 134.638 234.797 135.403 236.833 136.02L237.728 140.202L248.108 141.443L251.549 137.78C253.819 137.678 256.059 137.408 258.22 136.977L263.742 139.558L272.511 136.008L272.523 136.012V130.471C273.225 129.849 273.857 129.199 274.415 128.524L281.336 127.994L281.339 127.994L283.372 121.728L283.374 121.724V115.778L276.797 117.512C276.577 116.87 276.295 116.236 275.952 115.613L280.201 112.293L280.214 112.3V106.338L276.114 106.03L276.354 108.815L274.309 106.976L267.587 108.092ZM233.418 128.327C233.155 128.12 232.902 127.907 232.658 127.688C226.369 122.033 228.851 114.381 238.202 110.596C247.552 106.811 260.231 108.327 266.52 113.982C271.521 118.479 270.976 124.239 265.841 128.313L266.109 127.423L246.55 128.886L233.599 126.935L233.418 128.327Z"
          fill="#4CA05D"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M272.519 130.056L263.742 133.609L258.22 131.029C256.059 131.46 253.819 131.729 251.548 131.831L248.108 135.494L237.727 134.253L236.833 130.071C234.796 129.454 232.891 128.689 231.162 127.793L224.44 128.909L218.537 123.601L222.797 120.272C222.078 118.967 221.627 117.613 221.453 116.242L215.377 114.157L217.409 107.891L224.334 107.36C225.35 106.132 226.613 104.984 228.093 103.941L226.23 99.8796L235.007 96.3273L240.53 98.9071C242.69 98.4763 244.93 98.207 247.2 98.1052L250.64 94.441L261.021 95.6824L261.916 99.8649C263.952 100.482 265.857 101.247 267.587 102.143L274.309 101.027L280.212 106.335L275.952 109.664C276.67 110.97 277.122 112.323 277.295 113.694L283.372 115.779L281.339 122.045L274.415 122.576C273.398 123.804 272.136 124.952 270.656 125.995L272.519 130.056ZM232.658 121.739C238.947 127.394 251.626 128.91 260.976 125.126C270.327 121.341 272.809 113.689 266.52 108.034C260.23 102.379 247.552 100.862 238.201 104.647C228.851 108.432 226.369 116.084 232.658 121.739Z"
          fill="#5BBA6F"
        />
        <animate
          attributeName="opacity"
          dur="2s"
          begin="1.5s"
          repeatCount="indefinite"
          values="1;1;0;0"
          keyTimes="0;0.25;0.28;1"
        />
      </g>
      <g filter="url(#filter7_f)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M270.255 36.085H228.477V113.113H228.48C228.504 116.422 230.53 119.733 234.559 122.277C242.696 127.412 255.922 127.473 264.101 122.412C268.277 119.828 270.329 116.408 270.255 112.996V36.085Z"
          fill="white"
          fillOpacity="0.3"
        />
      </g>
      <path
        d="M296.367 9.97375H202.367L228.478 113.113C228.502 116.422 230.528 119.733 234.557 122.276C242.694 127.412 255.92 127.473 264.099 122.412C268.227 119.857 270.28 116.486 270.255 113.113L296.367 9.97375Z"
        fill="url(#paint23_linear)"
        fillOpacity="0.3"
      />
      <defs>
        <filter
          id="filter0_b"
          x="1.69528"
          y="7.45911"
          width="478.925"
          height="259.586"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImage" stdDeviation="3" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_f"
          x="227.124"
          y="143.537"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter2_f"
          x="227.124"
          y="147.017"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter3_f"
          x="227.124"
          y="150.498"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter4_f"
          x="295.124"
          y="121.537"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter5_f"
          x="295.124"
          y="125.017"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter6_f"
          x="295.124"
          y="128.498"
          width="18.7512"
          height="16.355"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="3" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="filter7_f"
          x="192.477"
          y="0.085022"
          width="113.78"
          height="162.083"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="18" result="effect1_foregroundBlur" />
        </filter>
        <radialGradient
          id="paint25_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(241.162 137.262) rotate(90) scale(126.377 240.38)"
        >
          <stop stopColor="#149082" stopOpacity="0.6" />
          <stop offset="0.994792" stopColor="#149082" stopOpacity="0.2" />
        </radialGradient>
        <linearGradient
          id="paint11_linear"
          x1="281.288"
          y1="81.9207"
          x2="281.379"
          y2="63.9715"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint12_linear"
          x1="330.962"
          y1="74.0705"
          x2="331.053"
          y2="56.1213"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint13_linear"
          x1="383.673"
          y1="101.543"
          x2="383.764"
          y2="83.594"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint14_linear"
          x1="353.351"
          y1="136.061"
          x2="353.442"
          y2="118.112"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint15_linear"
          x1="241.673"
          y1="195.152"
          x2="241.764"
          y2="177.203"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint16_linear"
          x1="193.673"
          y1="221.123"
          x2="193.764"
          y2="203.174"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint17_linear"
          x1="125.677"
          y1="219.397"
          x2="125.768"
          y2="201.448"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint18_linear"
          x1="89.2847"
          y1="183.503"
          x2="89.3757"
          y2="165.554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <linearGradient
          id="paint19_linear"
          x1="154.962"
          y1="167.606"
          x2="155.053"
          y2="149.657"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" />
        </linearGradient>
        <radialGradient
          id="paint11_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(246.103 133.34) rotate(90) scale(31.9767 60.318)"
        >
          <stop stopColor="#149082" />
          <stop offset="0.994792" stopColor="#1CB7A5" />
        </radialGradient>
        <radialGradient
          id="paint12_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(246.103 119.34) rotate(90) scale(31.9767 60.318)"
        >
          <stop stopColor="#149082" />
          <stop offset="0.994792" stopColor="#1CB7A5" />
        </radialGradient>
        <linearGradient
          id="paint23_linear"
          x1="243.115"
          y1="94.1208"
          x2="221.026"
          y2="68.4729"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" />
          <stop offset="1" stopColor="#40DC61" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

HomePhoneDrivit.propTypes = {
  variant: PropTypes.oneOf(["root", "rootHowItWorks"]),
};

HomePhoneDrivit.defaultProps = {
  variant: "root",
};

export default HomePhoneDrivit;
