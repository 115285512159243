import React from "react";

import styles from "./homeHeroMap.module.css";

const routeTime = 22;

const HomeHeroMap = () => {
  const renderSquare = (
    delay,
    linePath,
    lineStroke,
    shadowFilter,
    squareFill,
    squareX,
    squareY,
  ) => {
    return (
      <>
        <path d={linePath} stroke={lineStroke} opacity="0">
          <animateTransform
            attributeName="transform"
            type="translate"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0,60;0,-55;0,-55"
            keyTimes="0;0.1;1"
          />
          <animate
            attributeName="opacity"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0;0;1;1;0;0"
            keyTimes="0;0.025;0.05;0.08;0.1;1"
          />
        </path>
        <g opacity="0">
          <g opacity="0.6" filter={shadowFilter}>
            <rect
              x={squareX}
              y={squareY}
              width="5.87826"
              height="5.87826"
              fill="white"
              opacity="1"
            />
          </g>
          <rect
            x={squareX}
            y={squareY}
            width="5.87826"
            height="5.87826"
            fill={squareFill}
            opacity="1"
          />
          <animateTransform
            attributeName="transform"
            type="translate"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0,0;0,-140;0,-140"
            keyTimes="0;0.1;1"
          />
          <animate
            attributeName="opacity"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0;1;1;0;0"
            keyTimes="0;0.04;0.07;0.1;1"
          />
        </g>
      </>
    );
  };

  return (
    <svg
      className={styles.root}
      viewBox="0 0 1754 672"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M455.48 209.744L1110.64 403.721M410.666 117.156L720.744 208.963M569.377 34.8606L879.455 126.667M629.633 117.156L800.837 167.846M173.33 300.675L651.674 442.3M938.232 496.304L1267.42 593.767M370.98 398.638L603.172 467.384M1054.33 434.583L1383.51 532.045M833.895 414.009L1163.08 511.471M1163.07 375.8L1492.26 473.263M1293.87 306.73L1623.05 404.193M1519.44 318.669L1689.18 368.923M240.188 209.739L378.327 250.639M831.693 150.41L993.346 198.272M729.561 469.852L894.152 519.817M644.328 381.678L808.919 431.644M1102.83 269.991L1267.43 319.956M879.457 255.296L1044.05 305.261M964.682 619.748L1579.96 287.626M1293.87 505.066L1399.94 447.809M859.611 330.116L954.662 278.809M631.094 181.665L707.04 140.67M801.572 571.252L1416.85 239.13M805.973 496.304L1024.2 378.505M329.834 346.968L537.777 234.722M548.801 496.813L718.536 405.191M876.516 593.296L1013.19 519.522M1233.61 401.078L1370.28 327.304M638.445 521.287L1253.72 189.165M476.797 474.261L1092.07 142.139M74.1309 355.226L304.853 230.684M698.699 282.703L907.378 170.061M1177.77 294.974L1327.67 214.061M481.201 136.347L631.097 55.4346M316.609 428.704L931.886 96.5824M149.074 375.8L764.351 43.6782M1030.81 583.743L1188.06 570.517M1109.44 405.926L1246.84 400.048M1366.61 327.305C1366.61 327.305 1404.82 308.424 1443.76 306.73C1482.71 305.037 1524.59 319.222 1524.59 319.222M304.109 230.313C304.109 230.313 350.401 202.615 389.344 200.922C428.288 199.228 456.944 209.145 456.944 209.145M1023.47 377.269C1023.47 377.269 1063.88 358.9 1070.5 337.591C1077.11 316.282 1040.37 304.526 1040.37 304.526M991.142 195.043L956.607 278.074M643.59 383.883L700.168 282.483M623.277 4L8 336.122L1129.75 668.243L1745.03 336.122L623.277 4Z"
        stroke="#0D554F"
        strokeWidth="6"
      />
      <path
        d="M623.503 0L1757.01 332.53L1129.98 674L0 337.47L623.503 0Z"
        fill="url(#map_paint0_radial)"
      />

      {/* ROUTE 1 */}
      {renderSquare(
        0.11,
        "M656 301L656 349.496",
        "url(#map_paint2_linear)",
        "url(#map_filter1_f)",
        "url(#map_paint3_linear)",
        "653",
        "357",
      )}
      {renderSquare(
        0.18,
        "M716 211L716 259.496",
        "url(#map_paint4_linear)",
        "url(#map_filter2_f)",
        "url(#map_paint5_linear)",
        "713",
        "267",
      )}
      {renderSquare(
        0.25,
        "M816 159L816 207.496",
        "url(#map_paint6_linear)",
        "url(#map_filter3_f)",
        "url(#map_paint7_linear)",
        "813",
        "215",
      )}
      {renderSquare(
        0.32,
        "M922 115L922 163.496",
        "url(#map_paint8_linear)",
        "url(#map_filter4_f)",
        "url(#map_paint9_linear)",
        "919",
        "171",
      )}
      {renderSquare(
        0.425,
        "M973 223L973 271.496",
        "url(#map_paint10_linear)",
        "url(#map_filter5_f)",
        "url(#map_paint11_linear)",
        "970",
        "279",
      )}
      {renderSquare(
        0.6,
        "M932 302L932 350.496",
        "url(#map_paint12_linear)",
        "url(#map_filter6_f)",
        "url(#map_paint13_linear)",
        "929",
        "358",
      )}
      {renderSquare(
        0.73,
        "M893 387L893 435.496",
        "url(#map_paint14_linear)",
        "url(#map_filter7_f)",
        "url(#map_paint15_linear)",
        "890",
        "443",
      )}
      {renderSquare(
        0.86,
        "M764 392L764 440.496",
        "url(#map_paint16_linear)",
        "url(#map_filter8_f)",
        "url(#map_paint17_linear)",
        "761",
        "448",
      )}
      {renderSquare(
        0.97,
        "M686 361L686 409.496",
        "url(#map_paint18_linear)",
        "url(#map_filter9_f)",
        "url(#map_paint19_linear)",
        "683",
        "417",
      )}

      {/* ROUTE 2 */}
      {renderSquare(
        0.125,
        "M967 420L967 468.496",
        "url(#map_paint20_linear)",
        "url(#map_filter10_f)",
        "url(#map_paint21_linear)",
        "964",
        "476",
      )}
      {renderSquare(
        0.22,
        "M1093 352L1093 400.496",
        "url(#map_paint22_linear)",
        "url(#map_filter11_f)",
        "url(#map_paint23_linear)",
        "1090",
        "408",
      )}
      {renderSquare(
        0.38,
        "M1189 302L1189 350.496",
        "url(#map_paint24_linear)",
        "url(#map_filter12_f)",
        "url(#map_paint25_linear)",
        "1186",
        "358",
      )}
      {renderSquare(
        0.46,
        "M1310 251L1310 299.496",
        "url(#map_paint26_linear)",
        "url(#map_filter13_f)",
        "url(#map_paint27_linear)",
        "1307",
        "307",
      )}
      {renderSquare(
        0.58,
        "M1484 248L1484 296.496",
        "url(#map_paint28_linear)",
        "url(#map_filter14_f)",
        "url(#map_paint29_linear)",
        "1481",
        "304",
      )}
      {renderSquare(
        0.695,
        "M1387 330L1387 378.496",
        "url(#map_paint30_linear)",
        "url(#map_filter15_f)",
        "url(#map_paint31_linear)",
        "1384",
        "386",
      )}
      {renderSquare(
        0.86,
        "M1307 436L1307 484.496",
        "url(#map_paint32_linear)",
        "url(#map_filter16_f)",
        "url(#map_paint33_linear)",
        "1304",
        "492",
      )}
      {renderSquare(
        0.945,
        "M1175 445L1175 493.496",
        "url(#map_paint34_linear)",
        "url(#map_filter17_f)",
        "url(#map_paint35_linear)",
        "1172",
        "501",
      )}
      {renderSquare(
        0.04,
        "M1030 462L1030 510.496",
        "url(#map_paint36_linear)",
        "url(#map_filter18_f)",
        "url(#map_paint37_linear)",
        "1027",
        "518",
      )}

      {/* ROUTE 3 */}
      {renderSquare(
        0.75,
        "M177 239L177 287.496",
        "url(#map_paint0_linear)",
        "url(#map_filter0_f)",
        "url(#map_paint1_linear)",
        "174",
        "295",
      )}
      {renderSquare(
        0.85,
        "M317 161L317 209.496",
        "url(#map_paint38_linear)",
        "url(#map_filter19_f)",
        "url(#map_paint39_linear)",
        "314",
        "217",
      )}
      {renderSquare(
        0.95,
        "M478 137L478 185.496",
        "url(#map_paint40_linear)",
        "url(#map_filter20_f)",
        "url(#map_paint41_linear)",
        "475",
        "193",
      )}
      {renderSquare(
        0.06,
        "M503 63L503 111.496",
        "url(#map_paint42_linear)",
        "url(#map_filter21_f)",
        "url(#map_paint43_linear)",
        "500",
        "119",
      )}
      {renderSquare(
        0.12,
        "M596 13L596 61.4957",
        "url(#map_paint44_linear)",
        "url(#map_filter22_f)",
        "url(#map_paint45_linear)",
        "593",
        "69",
      )}
      {renderSquare(
        0.19,
        "M706 13L706 61.4957",
        "url(#map_paint46_linear)",
        "url(#map_filter23_f)",
        "url(#map_paint47_linear)",
        "703",
        "69",
      )}
      {renderSquare(
        0.275,
        "M686 73L686 121.496",
        "url(#map_paint48_linear)",
        "url(#map_filter24_f)",
        "url(#map_paint49_linear)",
        "683",
        "129",
      )}
      {renderSquare(
        0.39,
        "M706 143L706 191.496",
        "url(#map_paint50_linear)",
        "url(#map_filter25_f)",
        "url(#map_paint51_linear)",
        "703",
        "199",
      )}
      {renderSquare(
        0.49,
        "M586 186L586 234.496",
        "url(#map_paint52_linear)",
        "url(#map_filter26_f)",
        "url(#map_paint53_linear)",
        "583",
        "242",
      )}
      {renderSquare(
        0.59,
        "M436 227L436 275.496",
        "url(#map_paint54_linear)",
        "url(#map_filter27_f)",
        "url(#map_paint55_linear)",
        "433",
        "283",
      )}
      {renderSquare(
        0.69,
        "M296 276L296 324.496",
        "url(#map_paint56_linear)",
        "url(#map_filter28_f)",
        "url(#map_paint57_linear)",
        "293",
        "332",
      )}

      {/* defs */}
      <defs>
        <filter
          id="map_filter0_f"
          x="156"
          y="277"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter1_f"
          x="635"
          y="339"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter2_f"
          x="695"
          y="249"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter3_f"
          x="795"
          y="197"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter4_f"
          x="901"
          y="153"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter5_f"
          x="952"
          y="261"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter6_f"
          x="911"
          y="340"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter7_f"
          x="872"
          y="425"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter8_f"
          x="743"
          y="430"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter9_f"
          x="665"
          y="399"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter10_f"
          x="946"
          y="458"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter11_f"
          x="1072"
          y="390"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter12_f"
          x="1168"
          y="340"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter13_f"
          x="1289"
          y="289"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter14_f"
          x="1463"
          y="286"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter15_f"
          x="1366"
          y="368"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter16_f"
          x="1286"
          y="474"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter17_f"
          x="1154"
          y="483"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter18_f"
          x="1009"
          y="500"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter19_f"
          x="296"
          y="199"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter20_f"
          x="457"
          y="175"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter21_f"
          x="482"
          y="101"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter22_f"
          x="575"
          y="51"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter23_f"
          x="685"
          y="51"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter24_f"
          x="665"
          y="111"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter25_f"
          x="685"
          y="181"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter26_f"
          x="565"
          y="224"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter27_f"
          x="415"
          y="265"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id="map_filter28_f"
          x="275"
          y="314"
          width="41.8783"
          height="41.8783"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur stdDeviation="9" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient
          id="map_paint0_linear"
          x1="177"
          y1="239.429"
          x2="177"
          y2="286.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint1_linear"
          x1="176.939"
          y1="295"
          x2="176.939"
          y2="300.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint2_linear"
          x1="656"
          y1="301.429"
          x2="656"
          y2="348.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint3_linear"
          x1="655.939"
          y1="357"
          x2="655.939"
          y2="362.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint4_linear"
          x1="716"
          y1="211.429"
          x2="716"
          y2="258.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint5_linear"
          x1="715.939"
          y1="267"
          x2="715.939"
          y2="272.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint6_linear"
          x1="816"
          y1="159.429"
          x2="816"
          y2="206.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint7_linear"
          x1="815.939"
          y1="215"
          x2="815.939"
          y2="220.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint8_linear"
          x1="922"
          y1="115.429"
          x2="922"
          y2="162.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint9_linear"
          x1="921.939"
          y1="171"
          x2="921.939"
          y2="176.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint10_linear"
          x1="973"
          y1="223.429"
          x2="973"
          y2="270.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint11_linear"
          x1="972.939"
          y1="279"
          x2="972.939"
          y2="284.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint12_linear"
          x1="932"
          y1="302.429"
          x2="932"
          y2="349.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint13_linear"
          x1="931.939"
          y1="358"
          x2="931.939"
          y2="363.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint14_linear"
          x1="893"
          y1="387.429"
          x2="893"
          y2="434.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint15_linear"
          x1="892.939"
          y1="443"
          x2="892.939"
          y2="448.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint16_linear"
          x1="764"
          y1="392.429"
          x2="764"
          y2="439.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint17_linear"
          x1="763.939"
          y1="448"
          x2="763.939"
          y2="453.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint18_linear"
          x1="686"
          y1="361.429"
          x2="686"
          y2="408.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint19_linear"
          x1="685.939"
          y1="417"
          x2="685.939"
          y2="422.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint20_linear"
          x1="967"
          y1="420.429"
          x2="967"
          y2="467.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint21_linear"
          x1="966.939"
          y1="476"
          x2="966.939"
          y2="481.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint22_linear"
          x1="1093"
          y1="352.429"
          x2="1093"
          y2="399.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint23_linear"
          x1="1092.94"
          y1="408"
          x2="1092.94"
          y2="413.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint24_linear"
          x1="1189"
          y1="302.429"
          x2="1189"
          y2="349.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint25_linear"
          x1="1188.94"
          y1="358"
          x2="1188.94"
          y2="363.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint26_linear"
          x1="1310"
          y1="251.429"
          x2="1310"
          y2="298.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint27_linear"
          x1="1309.94"
          y1="307"
          x2="1309.94"
          y2="312.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint28_linear"
          x1="1484"
          y1="248.429"
          x2="1484"
          y2="295.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint29_linear"
          x1="1483.94"
          y1="304"
          x2="1483.94"
          y2="309.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint30_linear"
          x1="1387"
          y1="330.429"
          x2="1387"
          y2="377.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint31_linear"
          x1="1386.94"
          y1="386"
          x2="1386.94"
          y2="391.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint32_linear"
          x1="1307"
          y1="436.429"
          x2="1307"
          y2="483.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint33_linear"
          x1="1306.94"
          y1="492"
          x2="1306.94"
          y2="497.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint34_linear"
          x1="1175"
          y1="445.429"
          x2="1175"
          y2="492.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint35_linear"
          x1="1174.94"
          y1="501"
          x2="1174.94"
          y2="506.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint36_linear"
          x1="1030"
          y1="462.429"
          x2="1030"
          y2="509.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint37_linear"
          x1="1029.94"
          y1="518"
          x2="1029.94"
          y2="523.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint38_linear"
          x1="317"
          y1="161.429"
          x2="317"
          y2="208.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint39_linear"
          x1="316.939"
          y1="217"
          x2="316.939"
          y2="222.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint40_linear"
          x1="478"
          y1="137.429"
          x2="478"
          y2="184.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint41_linear"
          x1="477.939"
          y1="193"
          x2="477.939"
          y2="198.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint42_linear"
          x1="503"
          y1="63.429"
          x2="503"
          y2="110.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint43_linear"
          x1="502.939"
          y1="119"
          x2="502.939"
          y2="124.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint44_linear"
          x1="596"
          y1="13.429"
          x2="596"
          y2="60.6945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint45_linear"
          x1="595.939"
          y1="69"
          x2="595.939"
          y2="74.8783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint46_linear"
          x1="706"
          y1="13.429"
          x2="706"
          y2="60.6945"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint47_linear"
          x1="705.939"
          y1="69"
          x2="705.939"
          y2="74.8783"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint48_linear"
          x1="686"
          y1="73.429"
          x2="686"
          y2="120.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint49_linear"
          x1="685.939"
          y1="129"
          x2="685.939"
          y2="134.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint50_linear"
          x1="706"
          y1="143.429"
          x2="706"
          y2="190.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint51_linear"
          x1="705.939"
          y1="199"
          x2="705.939"
          y2="204.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint52_linear"
          x1="586"
          y1="186.429"
          x2="586"
          y2="233.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint53_linear"
          x1="585.939"
          y1="242"
          x2="585.939"
          y2="247.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint54_linear"
          x1="436"
          y1="227.429"
          x2="436"
          y2="274.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint55_linear"
          x1="435.939"
          y1="283"
          x2="435.939"
          y2="288.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="map_paint56_linear"
          x1="296"
          y1="276.429"
          x2="296"
          y2="323.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.2" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="map_paint57_linear"
          x1="295.939"
          y1="332"
          x2="295.939"
          y2="337.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>

        <radialGradient
          id="map_paint0_radial"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(841.173 363.774) rotate(-0.787613) scale(855.336 294.105)"
        >
          <stop stopColor="#0D5D56" stopOpacity="0" />
          <stop offset="1" stopColor="#0D5D56" stopOpacity="0.95" />
        </radialGradient>

        <path
          id="route1"
          d="M1102.82 545.036L939.5 496.5L1107 406.5L1244 400L1163.5 376L1292 306.5L1365.5 328C1365.5 328 1404.82 308.424 1443.76 306.73C1482.71 305.037 1522 319 1522 319L1327 424.171L1402.5 446.5L1293.5 505.5L1218 483L1102.82 545.036Z"
          pathLength="1599.4"
          className={styles.carHead}
        />
        <path
          id="route2"
          d="M551.234 158.659L479.171 137.439L634 54L705.756 75.2431L628.776 116.853L707.891 140.328L630.5 182L722.7 209.5L628 260.674L539 234.5L329.5 347L174.5 301L306.5 229.5C306.5 229.5 350.401 202.615 389.344 200.922C428.288 199.228 457 209.5 457 209.5L551.234 158.659Z"
          pathLength="1694"
          className={styles.carHead}
        />
        <path
          id="route3"
          d="M650.5 442L577 420L643 384.5L700.5 281.5L904 172L990.5 197.5L956.5 278.5L1040 304C1040 304 1077.11 316.282 1070.5 337.591C1063.88 358.9 1022.5 377.5 1022.5 377.5L946.5 355L836 414.5L914.5 437.5L809.777 494.197L732.5 470.5L806 430.5L719.5 404.5L650.5 442Z"
          pathLength="1618.8"
          className={styles.carHead}
        />
        <radialGradient
          id="fadeCar"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.1" offset="0.2" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="0.5" />
        </radialGradient>

        <radialGradient
          id="fadeTrail"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="400"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFFFFF" stopOpacity="0.3" offset="0" />
          <stop stopColor="#FFFFFF" stopOpacity="0.3" offset="0.2" />
          <stop stopColor="#FFFFFF" stopOpacity="0" offset="0.5" />
        </radialGradient>

        <mask id="car1" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute1} xlinkHref="#route1">
            <animate
              attributeName="stroke-dashoffset"
              from="96"
              to="-1503.4"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail1" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute1} xlinkHref="#route1">
            <animate
              attributeName="stroke-dashoffset"
              from="335"
              to="-1264.4"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="car2" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute2} xlinkHref="#route2">
            <animate
              attributeName="stroke-dashoffset"
              from="108"
              to="-1586"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail2" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute2} xlinkHref="#route2">
            <animate
              attributeName="stroke-dashoffset"
              from="353"
              to="-1341"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="car3" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute3} xlinkHref="#route3">
            <animate
              attributeName="stroke-dashoffset"
              from="100"
              to="-1518.8"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail3" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute3} xlinkHref="#route3">
            <animate
              attributeName="stroke-dashoffset"
              from="338"
              to="-1280.8"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car1)" }}>
        <circle style={{ fill: "url(#fadeCar)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route1" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail1)" }}>
        <circle style={{ fill: "url(#fadeTrail)" }} cx="0" cy="0" r="400">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route1" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#car2)" }}>
        <circle style={{ fill: "url(#fadeCar)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route2" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail2)" }}>
        <circle style={{ fill: "url(#fadeTrail)" }} cx="0" cy="0" r="400">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route2" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#car3)" }}>
        <circle style={{ fill: "url(#fadeCar)" }} cx="0" cy="0" r="100">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route3" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail3)" }}>
        <circle style={{ fill: "url(#fadeTrail)" }} cx="0" cy="0" r="400">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route3" />
          </animateMotion>
        </circle>
      </g>
    </svg>
  );
};

export default HomeHeroMap;
