import React from "react";
import PropTypes from "prop-types";

import styles from "./homePhoneBase.module.css";

const routeTime = 12;

const HomePhoneBase = ({ variant }) => {
  const renderSquare = (
    delay,
    linePath,
    lineStroke,
    squareFill,
    squareX,
    squareY,
  ) => {
    return (
      <>
        <path d={linePath} stroke={lineStroke} opacity="0">
          <animateTransform
            attributeName="transform"
            type="translate"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values={variant === "root" ? "0,40;0,5;0,5" : "0,60;0,-55;0,-55"}
            keyTimes="0;0.1;1"
          />
          <animate
            attributeName="opacity"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0;0;1;1;0;0"
            keyTimes={
              variant === "root"
                ? "0;0.035;0.05;0.08;0.1;1"
                : "0;0.025;0.05;0.08;0.1;1"
            }
          />
        </path>
        <rect
          x={squareX}
          y={squareY}
          width="5.87826"
          height="5.87826"
          fill={squareFill}
          opacity="0"
        >
          <animateTransform
            attributeName="transform"
            type="translate"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values={
              variant === "root" ? "0,0;0,-70;0,-70" : "0,0;0,-140;0,-140"
            }
            keyTimes="0;0.1;1"
          />
          <animate
            attributeName="opacity"
            dur={`${routeTime}s`}
            begin={`${delay * routeTime}s`}
            repeatCount="indefinite"
            values="0;1;1;0;0"
            keyTimes="0;0.04;0.07;0.1;1"
          />
        </rect>
      </>
    );
  };

  return (
    <svg
      className={styles[variant]}
      viewBox="0 0 736 596"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {/* base cor */}
      <path d="M734 398L367 596L0 398L367 200L734 398Z" fill="#F5F9FA" />
      {/* rede */}
      <path
        opacity="0.03"
        d="M186.242 495.257L547.151 300.708C547.151 300.708 587.292 320.051 586.225 347.651C585.158 375.25 547.992 394.451 547.992 394.451L274.075 542.58M456.059 251.629L369.459 298.254M96.25 446.771L188.797 395.977L316.342 364.256M641.396 444.806L402.411 315.967M280.328 250.343L402.411 315.967M460.399 542.324L99.4157 347.815M373.635 394.242L316.342 364.256M193.4 297.178L316.342 364.256M402.411 315.967L316.342 364.256M516.5 317.23L425 268.351M367 203.646L728 398.146L367 592.646L6 398.146L367 203.646Z"
        stroke="black"
        strokeWidth="6"
      />

      <path
        d="M145.046 388.547L150.742 379.226L396.181 245.633L424.142 233.723L442.266 237.866L583.108 311.912L584.144 332.106L328.349 474.502L296.245 479.68L147.117 393.207L145.046 388.547Z"
        fill="white"
        fillOpacity="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M587.324 312.593L449.408 238.535C432.374 230.304 420.435 231.29 406.552 238.203L150.795 377.133C137.048 383.977 134.646 402.711 146.216 412.85L278.381 484.912C290.665 495.675 308.258 497.714 322.646 490.041L582.973 347.818C595.75 341.004 598.911 321.302 587.324 312.593ZM457.899 249.302L441.62 240.523C432.374 235.713 422.752 236.964 413.676 241.433L155.713 379.929C149.899 382.793 148.932 390.735 153.887 394.929L288.999 466.665C296.77 473.243 307.713 474.448 316.712 469.717L574.652 330.893C580.481 327.828 582.938 317.885 577.685 313.899L548.136 297.964L530.693 299.781C529.897 299.864 529.094 299.706 528.389 299.328L456.398 260.73C454.466 259.694 453.728 257.298 454.741 255.354L457.899 249.302Z"
        fill="#D0D0D0"
      />

      {/* quadrados */}
      {renderSquare(
        0.415,
        "M362 340L362 388.496",
        "url(#phone_base_paint0_linear)",
        "url(#phone_base_paint1_linear)",
        "359",
        "396",
      )}
      {renderSquare(
        0.495,
        "M309 369L309 417.496",
        "url(#phone_base_paint2_linear)",
        "url(#phone_base_paint3_linear)",
        "306",
        "425",
      )}
      {renderSquare(
        0.59,
        "M245 366L245 414.496",
        "url(#phone_base_paint4_linear)",
        "url(#phone_base_paint5_linear)",
        "242",
        "422",
      )}
      {renderSquare(
        0.685,
        "M208 330L208 378.496",
        "url(#phone_base_paint6_linear)",
        "url(#phone_base_paint7_linear)",
        "205",
        "386",
      )}
      {renderSquare(
        0.77,
        "M275 313L275 361.496",
        "url(#phone_base_paint8_linear)",
        "url(#phone_base_paint9_linear)",
        "272",
        "369",
      )}
      {renderSquare(
        0.03,
        "M394 223L394 271.496",
        "url(#phone_base_paint10_linear)",
        "url(#phone_base_paint11_linear)",
        "391",
        "279",
      )}
      {renderSquare(
        0.1,
        "M451 221L451 269.496",
        "url(#phone_base_paint12_linear)",
        "url(#phone_base_paint13_linear)",
        "448",
        "277",
      )}
      {renderSquare(
        0.175,
        "M504 249L504 297.496",
        "url(#phone_base_paint14_linear)",
        "url(#phone_base_paint15_linear)",
        "501",
        "305",
      )}
      {renderSquare(
        0.27,
        "M471 280L471 328.496",
        "url(#phone_base_paint16_linear)",
        "url(#phone_base_paint17_linear)",
        "468",
        "336",
      )}

      {/* defs */}
      <defs>
        <linearGradient
          id="phone_base_paint0_linear"
          x1="362"
          y1="340.429"
          x2="362"
          y2="387.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint1_linear"
          x1="361.939"
          y1="396"
          x2="361.939"
          y2="401.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint2_linear"
          x1="309"
          y1="369.429"
          x2="309"
          y2="416.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint3_linear"
          x1="308.939"
          y1="425"
          x2="308.939"
          y2="430.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint4_linear"
          x1="245"
          y1="366.429"
          x2="245"
          y2="413.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint5_linear"
          x1="244.939"
          y1="422"
          x2="244.939"
          y2="427.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint6_linear"
          x1="208"
          y1="330.429"
          x2="208"
          y2="377.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint7_linear"
          x1="207.939"
          y1="386"
          x2="207.939"
          y2="391.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint8_linear"
          x1="275"
          y1="313.429"
          x2="275"
          y2="360.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint9_linear"
          x1="274.939"
          y1="369"
          x2="274.939"
          y2="374.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint10_linear"
          x1="394"
          y1="223.429"
          x2="394"
          y2="270.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint11_linear"
          x1="393.939"
          y1="279"
          x2="393.939"
          y2="284.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint12_linear"
          x1="451"
          y1="221.429"
          x2="451"
          y2="268.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint13_linear"
          x1="450.939"
          y1="277"
          x2="450.939"
          y2="282.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint14_linear"
          x1="504"
          y1="249.429"
          x2="504"
          y2="296.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint15_linear"
          x1="503.939"
          y1="305"
          x2="503.939"
          y2="310.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint16_linear"
          x1="471"
          y1="280.429"
          x2="471"
          y2="327.695"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#73E2A7" stopOpacity="0.6" />
          <stop offset="1" stopColor="#73E2A7" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="phone_base_paint17_linear"
          x1="470.939"
          y1="336"
          x2="470.939"
          y2="341.878"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEDAE" />
          <stop offset="1" stopColor="#F4D35E" />
        </linearGradient>

        <path
          id="route4"
          d="M369.459 298.254L425 268.351L516.5 317.23L279.626 444.918L188.797 395.977L316.342 364.256L402.411 315.967L369.459 298.254Z"
          pathLength="806.6"
          className={styles.carHead}
        />

        <radialGradient
          id="fadeCar2"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="70"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="1" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.3" offset="0.5" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <radialGradient
          id="fadeTrail2"
          cx="0"
          cy="0"
          fx="0"
          fy="0"
          r="200"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5BBA6F" stopOpacity="0.3" offset="0" />
          <stop stopColor="#5BBA6F" stopOpacity="0.3" offset="0.2" />
          <stop stopColor="#5BBA6F" stopOpacity="0" offset="1" />
        </radialGradient>

        <mask id="car4" maskUnits="userSpaceOnUse">
          <use className={styles.maskCarRoute4} xlinkHref="#route4">
            <animate
              attributeName="stroke-dashoffset"
              from="90"
              to="-716.6"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>

        <mask id="trail4" maskUnits="userSpaceOnUse">
          <use className={styles.maskTrailRoute4} xlinkHref="#route4">
            <animate
              attributeName="stroke-dashoffset"
              from="240"
              to="-566.6"
              dur={`${routeTime}s`}
              repeatCount="indefinite"
            />
          </use>
        </mask>
      </defs>

      <g style={{ mask: "url(#car4)" }}>
        <circle style={{ fill: "url(#fadeCar2)" }} cx="0" cy="0" r="70">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route4" />
          </animateMotion>
        </circle>
      </g>

      <g style={{ mask: "url(#trail4)" }}>
        <circle style={{ fill: "url(#fadeTrail2)" }} cx="0" cy="0" r="200">
          <animateMotion
            keyPoints="0;1"
            keyTimes="0;1"
            dur={`${routeTime}s`}
            repeatCount="indefinite"
          >
            <mpath xlinkHref="#route4" />
          </animateMotion>
        </circle>
      </g>
    </svg>
  );
};

HomePhoneBase.propTypes = {
  variant: PropTypes.oneOf(["root", "rootHowItWorks"]),
};

HomePhoneBase.defaultProps = {
  variant: "root",
};

export default HomePhoneBase;
