import React from "react";
import PropTypes from "prop-types";

import styles from "./homePhoneApp.module.css";

const HomePhoneApp = ({ variant }) => {
  return (
    <svg
      className={styles[variant]}
      viewBox="0 0 482 254"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M186.91 248.081L471.439 97.2552C475.714 94.989 475.686 88.8545 471.391 86.6274L314.087 5.06819C308.266 2.05021 301.336 2.07695 295.539 5.13976L10.9009 155.518C6.62425 157.778 6.64164 163.909 10.931 166.145L168.301 248.146C174.137 251.188 181.096 251.163 186.91 248.081Z"
        fill="#F4ECCD"
        fillOpacity="0.7"
      />
      <path
        d="M186.91 248.081L471.439 97.2552C475.714 94.989 475.686 88.8545 471.391 86.6274L314.087 5.06819C308.266 2.05021 301.336 2.07695 295.539 5.13976L10.9009 155.518C6.62425 157.778 6.64164 163.909 10.931 166.145L168.301 248.146C174.137 251.188 181.096 251.163 186.91 248.081Z"
        stroke="white"
      />
      <path
        d="M283.318 37.8553C279.788 35.973 275.553 35.973 272.024 37.8553L245.134 52.1965C243.016 53.3259 243.016 56.3612 245.134 57.4906L272.024 71.8318C275.553 73.7142 279.788 73.7142 283.318 71.8318L310.208 57.4906C312.325 56.3612 312.325 53.3259 310.208 52.1965L283.318 37.8553Z"
        fill="white"
      />
      <path
        d="M166.757 149.709C163.228 147.827 158.993 147.827 155.463 149.709L128.573 164.05C126.456 165.18 126.456 168.215 128.573 169.344L155.463 183.685C158.993 185.568 163.228 185.568 166.757 183.685L193.647 169.344C195.765 168.215 195.765 165.18 193.647 164.05L166.757 149.709Z"
        fill="white"
      />
      <path
        d="M119.757 123.709C116.228 121.827 111.993 121.827 108.463 123.709L81.5735 138.05C79.4558 139.18 79.4558 142.215 81.5735 143.344L108.463 157.685C111.993 159.568 116.228 159.568 119.757 157.685L146.647 143.344C148.765 142.215 148.765 139.18 146.647 138.05L119.757 123.709Z"
        fill="white"
      />
      <path
        d="M147.912 106.733C145.539 105.462 142.686 105.47 140.32 106.755L131.501 111.542C129.402 112.681 129.411 115.697 131.517 116.824L255.407 183.144C258.967 185.05 263.247 185.037 266.796 183.111L273.719 179.352C275.818 178.213 275.809 175.198 273.704 174.071L147.912 106.733Z"
        fill="white"
      />
      <path
        d="M233.287 63.8124C229.765 61.9558 225.551 61.9671 222.039 63.8426L162.309 95.7388C160.196 96.8672 160.193 99.8953 162.303 101.028L284.883 166.831C288.407 168.723 292.642 168.734 296.177 166.862L357.297 134.484C359.427 133.355 359.424 130.303 357.291 129.179L233.287 63.8124Z"
        fill="white"
      />
      <path
        d="M377.377 90.62C374.435 89.0514 370.906 89.0514 367.965 90.62L341.075 104.961C340.369 105.338 340.369 106.349 341.075 106.726L367.965 121.067C370.906 122.636 374.435 122.636 377.377 121.067L404.266 106.726C404.972 106.349 404.972 105.338 404.266 104.961L377.377 90.62Z"
        fill="white"
        stroke="white"
        strokeWidth="4"
        strokeLinejoin="round"
      />
      <path
        d="M328.435 67.3847C326.083 66.1298 323.259 66.1298 320.906 67.3847L295.671 80.8436L320.906 94.3024C323.259 95.5573 326.083 95.5573 328.435 94.3024L353.671 80.8436L328.435 67.3847Z"
        fill="white"
        stroke="white"
        strokeWidth="8"
        strokeLinejoin="round"
      />
      <path
        d="M282.847 38.7377C279.612 37.0122 275.73 37.0122 272.494 38.7377L245.605 53.0789C244.193 53.8318 244.193 55.8553 245.605 56.6083L272.494 70.9494C275.73 72.6749 279.612 72.6749 282.847 70.9494L309.737 56.6083C311.149 55.8553 311.149 53.8318 309.737 53.0789L282.847 38.7377Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square1}
      />
      <path
        d="M329.847 64.7377C326.612 63.0122 322.73 63.0122 319.494 64.7377L292.605 79.0789C291.193 79.8318 291.193 81.8553 292.605 82.6083L319.494 96.9494C322.73 98.6749 326.612 98.6749 329.847 96.9494L356.737 82.6083C358.149 81.8553 358.149 79.8318 356.737 79.0789L329.847 64.7377Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square2}
      />
      <path
        d="M377.847 89.7377C374.612 88.0122 370.73 88.0122 367.494 89.7377L340.605 104.079C339.193 104.832 339.193 106.855 340.605 107.608L367.494 121.949C370.73 123.675 374.612 123.675 377.847 121.949L404.737 107.608C406.149 106.855 406.149 104.832 404.737 104.079L377.847 89.7377Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square3}
      />
      <path
        d="M232.821 64.6971C229.592 62.9951 225.729 63.0055 222.51 64.7248L162.78 96.6209C161.371 97.3732 161.369 99.3919 162.776 100.147L285.356 165.95C288.587 167.684 292.469 167.695 295.708 165.978L356.829 133.6C358.249 132.848 358.247 130.813 356.825 130.063L232.821 64.6971Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square4}
      />
      <path
        d="M147.44 107.614C145.364 106.503 142.867 106.51 140.797 107.634L131.978 112.421C130.579 113.181 130.585 115.191 131.988 115.942L255.879 182.263C259.142 184.009 263.065 183.998 266.318 182.232L273.242 178.473C274.641 177.714 274.635 175.704 273.232 174.952L147.44 107.614Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square5}
      />
      <path
        d="M119.287 124.591C116.051 122.866 112.169 122.866 108.934 124.591L82.0441 138.932C80.6323 139.685 80.6323 141.709 82.0441 142.462L108.934 156.803C112.169 158.529 116.051 158.529 119.287 156.803L146.176 142.462C147.588 141.709 147.588 139.685 146.176 138.932L119.287 124.591Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square6}
      />
      <path
        d="M166.287 150.591C163.051 148.866 159.169 148.866 155.934 150.591L129.044 164.932C127.632 165.685 127.632 167.709 129.044 168.462L155.934 182.803C159.169 184.529 163.051 184.529 166.287 182.803L193.176 168.462C194.588 167.709 194.588 165.685 193.176 164.932L166.287 150.591Z"
        fill="#5BBA6F"
        opacity="0"
        className={styles.square7}
      />

      <path
        d="M303.972 21.04L317.763 28.5157L315.347 30.0298L301.52 22.3909L303.972 21.04Z"
        fill="white"
      />
      <path
        d="M298.726 23.8651L312.517 31.3408L310.101 32.8549L296.274 25.216L298.726 23.8651Z"
        fill="white"
      />
      <path
        d="M293.224 26.8537L307.015 34.3294L304.599 35.8435L290.772 28.2046L293.224 26.8537Z"
        fill="white"
      />
    </svg>
  );
};

HomePhoneApp.propTypes = {
  variant: PropTypes.oneOf(["root", "rootHowItWorks"]),
};

HomePhoneApp.defaultProps = {
  variant: "root",
};

export default HomePhoneApp;
